<template>
    <div>

        <a-row>

            <div class="messages-list">

                <Message v-for="message in messagesFiltered" :key="message._id" :author="message.username"
                    :text="message.content" :dark="message.system" :files="message.files" :timestamp="message.timestamp"></Message>


                    <div style="text-align: center; margin: auto;" v-if="messages.length<1">
                        <vue-loaders-ball-scale-ripple-multiple  :loading="loading" :color="'#1890ff'" :background="'#ffffff'" :size="'5px'" :opacity="'0.5'" :z-index="9999" :padding="'1px'"></vue-loaders-ball-scale-ripple-multiple>
                    </div>

                    

            </div>




        </a-row><br>

        <a-row>

            <ChatBox v-if="roomId != null" :roomId="roomId" @submitChat="submitChat"/>

        </a-row>

    </div>
</template>

<script>

import Message from './Message.vue'

import ChatBox from './ChatBox.vue'

export default ({
    name: 'Room',
    components: {
        'Message': Message,
        'ChatBox': ChatBox,
    },

    computed: {
        messagesFiltered() {
            return this.messages.filter(message => message.senderId === this.roomId);
        }
    },

    async mounted() {
        console.log('Room mounted');
        // console.log(this.props.roomId);

        

    },

    props: {
        roomId: {
            type: String,
            default: '',
        },
        messages: {
            type: Array,
            default: [],
        },

    },
    methods: {
        submitChat(event, text) {
            console.log(text);

            console.log(event);

            const item = {
                senderId: this.roomId,
                content: text,
                system: false,
            };

            console.log('emitted event')

            this.$emit('submitChatRoom', item);
        },
    },
    data() {
        return {
            loading: false,
        }
    },
})

</script>

<style scoped>
.messages-list {
    max-height: 30lvh;
    overflow-y: scroll;
    padding: 0 1rem;
}
</style>