<template>


    <div>



        <sweet-modal ref="errorfetchmodal"  title="Error" icon="error">

            <h6>There was an error fetching the data, please try again later.</h6>

        </sweet-modal>


        <sweet-modal ref="phonemodal"  title="Choose number">

            <h6>Select a number below:</h6>

            <a-row>
                <a-col :span="24" :xl="12" class="mb-24" v-for="number in numbers" style="margin: 3px;">
                    <a-button type="primary" block style="margin: 3px;" @click="selectNumber(number)">
                        {{ number.display_phone_number }}
                    </a-button>
                </a-col>
            </a-row>

            <vue-loaders-ball-scale-ripple-multiple
                v-if="loading"
                :loading="loading"
                :color="'#1890ff'"
                :background="'#ffffff'"
                :size="'5px'"
                :opacity="'0.5'"
                :z-index="9999"
                :padding="'1px'"
                style="margin-left: 25%; margin-top: 5%;"
            >
            </vue-loaders-ball-scale-ripple-multiple>

            


        </sweet-modal>

    <a-card :bordered="false" class="widget-1">

        <a-row>

            <a-col :span="24" :xl="12" class="mb-24">


                <a-form v-if="channel_data.channel_provider == 'whabiz'">

 
                    <a-form-item label="Whatsapp Number">
                        <a-input v-model="channel_data.wa_number" :placeholder="'Whatsapp Number'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Business ID">
                        <a-input v-model="channel_data.waba_id" :placeholder="'Whatsapp Business ID'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Phone ID">
                        <a-input v-model="channel_data.number_id" :placeholder="'Whatsapp Phone ID'" :disabled="false">
                        </a-input>
                    </a-form-item>

                    <a-form-item label="Whatsapp Business Token">
                        <a-input v-model="channel_data.waba_token" :placeholder="'Whatsapp Business Token'" :disabled="false">
                        </a-input>
                    </a-form-item>


                    <a-form-item label="Messages Webhook">
                        <a-input v-model="channel_data.messages_webhook" :placeholder="'Messages Webhook url'" :disabled="false">
                        </a-input>
                    </a-form-item>


                    <a-button type="secondary" block style="margin-left: 1px;" @click="requestAddFbAccount">
                    <i class="fa fa-facebook" style="margin-right: 7px;"></i>{{ 'Log In With Facebook' }}
                    </a-button><br><br>


                    <a-button type="secondary" block style="margin-left: 1px;" @click="getNumbersList">
                    <i class="fa fa-phone" style="margin-right: 7px;"></i>{{ 'Choose Number' }}
                    </a-button>

                    <vue-loaders-ball-scale-ripple-multiple  
                    v-if="loading"
                    :loading="loading" 
                    :color="'#1890ff'" 
                    :background="'#ffffff'" 
                    :size="'5px'" 
                    :opacity="'0.5'" 
                    :z-index="9999" 
                    :padding="'1px'"
                    style="margin-left: 25%; margin-top: 5%;"
                    >
                    </vue-loaders-ball-scale-ripple-multiple>


                
                </a-form>

                <a-form v-if="channel_data.channel_provider == 'twilio'">


                    <a-form-item label="Twilio Number">
                        <a-input v-model="channel_data.twilio_number" :placeholder="'Twilio Number'" :disabled="false">
                        </a-input>
                    </a-form-item>



                    <a-form-item label="Twilio Account SID">
                        <a-input v-model="channel_data.account_sid" :placeholder="'Twilio Account SID'"
                            :disabled="false">
                        </a-input>
                    </a-form-item>


                
                    

                    <a-form-item label="Twilio Account Token">
                        <a-input v-model="channel_data.twilio_token" :placeholder="'Twilio Token'" :disabled="false">
                        </a-input>
                    </a-form-item>


                </a-form>

            </a-col>


        </a-row>



    </a-card>

    </div>

</template>

<script>

import { getWhapiLoginLink, fetchDataJSON, fetchPostJSON } from '../../services/v1/services';

import { currentToken } from '../../services/v1/data_methods';

export default ({
    props: {
        channel_data: {
            type: Object,
            default: {},
        },
        status: {
            type: String,
            default: "success",
        },
        channelId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
            numbers: [],
        }
    },
    methods: {
        async requestAddFbAccount() {
            console.log('requestAddFbAccount');
            let link = getWhapiLoginLink();

            link = link + '?utok=' + currentToken() + '&channel=' + this.channelId;
            console.log('link', link);

            //opne the link in new tab
            window.open(link, '_blank');
        },

        async selectNumber(number){
            console.log('selectNumber', number);

            const path = 'projects/channels/'+this.channelId+'/set_phone';

            const data = {
                phone_id: number.id,
            }

            this.loading = true;

            const response = await fetchPostJSON(path, data);

            console.log('response', response);

            if (!response.Ok) {
                this.$refs.errorfetchmodal.open();
                this.loading = false;
                return;
            }

            this.channel_data.number_id = number.id;

            this.loading = false;

            this.$refs.phonemodal.close();
        },

        async getNumbersList(){
            console.log('getNumbersList');

            if(!this.channel_data.waba_id){
                this.$message.error('Please add Whatsapp Business ID first, get it from Facebook Business Manager');
                return;
            }

            const path = 'projects/channels/'+this.channelId+'/numbers/list';

            this.loading = true;

            const response = await fetchDataJSON(path, 'GET');

            console.log('response', response);

            if (!response.Ok) {
                this.$refs.errorfetchmodal.open();
                this.loading = false;
                return;
            }

            this.numbers = response.data.numbers;

            this.$refs.phonemodal.open();

            this.loading = false;
           
        }
    }
})

</script>